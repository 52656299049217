import React, { Component } from "react"
import { graphql } from 'gatsby'
import PropTypes from "prop-types"
import { withBreakpoints } from "react-breakpoints"

import Fade from "react-reveal/Fade"
import ReactCursorPosition from "@appinfini/react-cursor-position"
import FigureBox, { boxFormats } from "../../components/FigureBox"
import TextAnimation from "../../components/TextAnimation"
import InlinePlayer from "../../components/InlinePlayer"

class Text extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playerIsFullscreen: false,
      playerFullScreenClassName: `is-fullscreen`,
      breakLinesWidth: 35,
    }
  }

  wrapperClassName() {
    let classes = [`text`]

    if (this.props.figureBackgroundCover) {
      classes.push(`has-background-cover`)
    }

    if (this.props.overflowContainer) {
      classes.push(`overflow-container`)
    }

    return classes.join(` `)
  }

  containerClassName() {
    return this.props.container ? `container-fluid` : ``
  }

  figureClassName() {
    let classes = [`text__images`]
    if (this.props.figureBackgroundCover) {
      classes.push(`has-background-cover`)
    }

    return classes.join(` `)
  }

  videoClassName() {
    let classes = [`text__video`]

    if (this.state.playerIsFullscreen) {
      classes.push(this.state.playerFullScreenClassName)
    }

    return classes.join(` `)
  }

  images() {
    return this.props.text.images.map((image, index) => (
      <FigureBox
        key={index}
        source={image}
        format={this.props.figureFormat}
      />
    ))
  }

  videoPoster() {
    if (this.props.text.images[0]) {
      return this.props.text.images[0].resolutions.src
    } else {
      return null
    }
  }

  render() {
    return (
      <div className={`${this.wrapperClassName()}`}>
        <div className={`${this.containerClassName()}`}>
          {/* regular text */}
          {this.props.text.content &&
            !this.props.breakTextAnimation && (
              <Fade duration={1000} delay={50}>
                <div
                  className={`text__content ${this.props.contentClassName}`}
                  dangerouslySetInnerHTML={{
                    __html: this.props.text.content.childMarkdownRemark.html,
                  }}
                />
              </Fade>
            )}

          {/* animate text */}
          {this.props.text.content &&
            this.props.breakTextAnimation && (
              <div className={this.props.contentClassName}>
                <TextAnimation
                  wrapperElement={this.props.textWrapperTag}
                  content={this.props.text.content.childMarkdownRemark.html}
                />
              </div>
            )}
        </div>

        {this.props.text.images &&
          !this.props.text.video && (
            <figure className={this.figureClassName()}>{this.images()}</figure>
          )}

        {this.props.text.video && (
          <figure ref="text__video">
            <ReactCursorPosition
              hoverDelayInMs={0}
              hoverOffDelayInMs={0}
              followCursor={true}
            >
              <InlinePlayer
                parent={this}
                src={this.props.text.video.file.url}
                resume={true}
              />
            </ReactCursorPosition>
          </figure>
        )}
      </div>
    )
  }
}

Text.defaultProps = {
  container: true,
  contentClassName: ``,
  overflowContainer: false,
  figureBackgroundCover: false,
  figureFormat: boxFormats.square,
  breakTextAnimation: false,
  breakLinesWidth: 30,
  textWrapperTag: null,
}

Text.propTypes = {
  text: PropTypes.object.isRequired,
}

export default withBreakpoints(Text)

//
// Each text fields
//
export const textFragment = graphql`
  fragment textFragment on ContentfulTexts {
    id
    description
    slug
    images {
      id
      gatsbyImageData(width: 1600, quality: 80)
    }
    video {
      id
      file {
        url
        fileName
        contentType
      }
    }
    content {
      id
      childMarkdownRemark {
        html
      }
    }
  }
`
