import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Text, { textFragment } from "../content/texts/Text"


class NotFoundPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <SEO title={`404 - Page not found`} description={`404`} bodyClass= {`is-404`} />
      
        <section className="static_page 404-page container-fluid">

          <header className="row contact_page__header mb-5">
            {this.props.data.page && (
              <h1 className="col-12 xl">{this.props.data.page.description}</h1>
            )}
          </header>

          <div className="row">
            {this.props.data.page && (
              <aside className="contact_page__intro_text col-12 col-md-10 col-lg-7">
                <Text text={this.props.data.page} container={false} />
              </aside>
            )}
          </div>
        </section>
      </Layout>
    )
  }
}

NotFoundPage.defaultProps = {
  pageAttributes: {
    bodyClass: `is-404`,
    title: `404 - Page not found`,
  },
}

export default NotFoundPage

export const query = graphql`
  query NonFoundPageQuery {
    page: contentfulTexts(slug: { eq: "404-page" }) {
      ...textFragment
    }
  }
`
