import React from 'react'
import striptags from "striptags"
import PropTypes from "prop-types"
import Slide from "react-reveal/Slide"

export default function TextAnimation(props) {
    function words(html) {
        let words = striptags(html)
        .toString()
        .split(` `)

        return words.map((word, index) => (
        <span key={index} className="text_word__hidden_overflow">
            <Slide up distance="100%" duration={750} delay={10 * index}>
            <span className="text_line__word">
                {word}
                &nbsp;
            </span>
            </Slide>
        </span>
        ))
    }
  return (
    <props.wrapperElement>
        {words(props.content)}
    </props.wrapperElement>
  )
}
TextAnimation.defaultProps = {
  wrapperElement: `h1`,
}

TextAnimation.propTypes = {
  content: PropTypes.string.isRequired,
  wrapperElement: PropTypes.string.isRequired,
}
